import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { CxDialogService } from '@bbraun/cortex/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { take } from 'rxjs';

import { User } from '../../interfaces/user/user.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class HeaderComponent {
  userData = input.required<User>();
  title = input<string>('');
  loading = input<boolean>(true);

  constructor(
    private readonly msalService: MsalService,
    private readonly translocoService: TranslocoService,
    private readonly dialogService: CxDialogService,
    readonly router: Router
  ) {}

  openLogOutDialog(): void {
    this.dialogService
      .openConfirmDialog(
        this.translocoService.translate('header.logOutDialog.title'),
        this.translocoService.translate('header.logOutDialog.description'),
        this.translocoService.translate('header.logOutDialog.confirmButton'),
        this.translocoService.translate('header.logOutDialog.cancelButton')
      )
      .pipe(take(1))
      .subscribe((result: boolean) => {
        if (!result) {
          return;
        }

        this.msalService.logout({
          idTokenHint: this.msalService.instance.getAllAccounts()[0].idToken
        });
      });
  }
}
