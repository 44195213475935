import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { MsalService } from '@azure/msal-angular';
import { CxSnackbarService } from '@bbraun/cortex/snackbar';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, take } from 'rxjs';

import { UserStateService } from '../../+state/user-state/user-state.service';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AvatarComponent implements OnInit {
  token: any;

  @ViewChild('avatarInput') avatarInput?: ElementRef<HTMLInputElement>;

  userStateService = inject(UserStateService);

  constructor(
      public dialog: MatDialog,
      private readonly authService: MsalService,
      private readonly translocoService: TranslocoService,
      private readonly snackbarService: CxSnackbarService) { }

  ngOnInit(): void {
    this.token = this.authService?.instance?.getAllAccounts()[0]?.idToken;
  }

  onFileChange(event: any): void {
    const files = event.target.files as FileList;

    if (files.length > 0 && (files[0].type === 'image/jpeg'
        || files[0].type === 'image/jpg'
        || files[0].type === 'image/png')) {
      const _file = URL.createObjectURL(files[0]);
      this.resetInput();
      this.openAvatarEditor(_file)
        .pipe(
          take(1))
        .subscribe(
          (result: Blob) => {
            if (result) {
              this.userStateService.updateAvatar(result);
            }
          }
        );
    } else {
      this.snackbarService.error(this.translocoService.translate('details.errorAvatarFormat'));
    }
  }

  openAvatarEditor(image: string): Observable<any> {
    const dialogRef = this.dialog.open(ImageCropperComponent, {
      maxWidth: '80vw',
      maxHeight: '90vh',
      data: image,
    });

    return dialogRef.afterClosed().pipe(take(1));
  }

  onDeleteAvatarClick(): void {
    this.userStateService.deleteAvatar();
  }

  resetInput(): void {
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }
}
